import React, {useEffect, useState} from "react"
import axios from "axios"
import {Grid, TablePagination, Typography} from "@material-ui/core"
import DashboardIcon from "@material-ui/icons/Dashboard"
import ConfessionCard from "./ConfessionCard"
import GoToPage from "./GoToPage"
import SetOrder from "./SetOrder"
import StatusFilter from "./StatusFilter"
import {StatusString} from "../../../server/models/Confession/ConfessionCollection"
import SearchBar from "./SearchBar"
import SetLastConfessionID from "./SetLastConfessionID"
import {ConfessionPublic} from "../../../server/models/Confession/ConfessionDocument"
import ProgressDialog from "../ProgressDialog"

export default function Dashboard() {
    const [loading, setLoading] = useState<boolean>(false)
    const [confessions, setConfessions] = useState<ConfessionPublic>({})
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(48)
    const [page, setPage] = useState<number>(0)
    const [totalCount, setTotalCount] = useState<number>(0)
    const [order, setOrder] = useState<number>(localStorage["order"] ? parseInt(localStorage["order"]) : 1)
    const [filteredStatus, setFilteredStatus] = useState<StatusString[]>((localStorage.getItem("filteredStatus")) ? JSON.parse(localStorage["filteredStatus"]) : ["approved", "pending"])
    const [searchText, setSearchText] = useState("")
    const [searchCriteria, setSearchCriteria] = useState("message")
    const [lastconfessionid, setLastconfessionid] = useState<string>("")
    const [copyFlags, setCopyFlags] = useState<boolean[]>(Array(rowsPerPage).fill(false))
    const [errorMsg, setErrorMsg] = React.useState<string>("")
    let numOfPages = Math.max(0, Math.ceil((totalCount) / rowsPerPage) - 1)

    useEffect(() => {
        (async () => {
            try {
                const res = await axios.post('/api/user/checkaccess')
                setLastconfessionid(res.data.lastconfessionid)
                numOfPages = Math.max(0, Math.ceil((totalCount) / rowsPerPage) - 1)
                if (page > numOfPages)
                    setPage(0)
                setLoading(true)
                localStorage.setItem('filteredStatus', JSON.stringify(filteredStatus))
                localStorage.setItem('order', String(order))
                if (searchCriteria == "_id") {
                    setSearchText(searchText.split("-")[0])
                }

                const r = await axios.post('/api/confession/read', {
                    order,
                    page,
                    rowsPerPage,
                    allowedStatus: filteredStatus,
                    searchText,
                    searchCriteria
                })
                setCopyFlags(Array(rowsPerPage).fill(false))
                setLoading(false)
                setTotalCount(parseInt(r.data.totalCount, 10))
                setConfessions(r.data.confessions)
            } catch (error) {
                setErrorMsg(error.response.data.errors[0].msg)
            }
        })()
    }, [page, rowsPerPage, order, filteredStatus, searchText, searchCriteria])


    useEffect(() => {
        (async () => {
            if (Object.keys(confessions).length === 0 && page > 0)
                setPage(page - 1)
        })()
    }, [confessions])

    const removeConfession = (i: string): void => {
        setTotalCount(totalCount - 1)

        const filteredConfessions = Object.fromEntries(
            Object.entries(confessions).filter(
                ([key, _]) =>
                    key !== String(i)
            ))
        setConfessions(filteredConfessions)
    }

    return (
        <Grid container alignItems={"center"} spacing={3}>
            <Grid container item xs={12} justifyContent={"space-between"}>
                <Grid item><DashboardIcon/></Grid>
                <Grid item>
                    <Typography component="h1" variant="h5">
                        Dashboard
                    </Typography>
                </Grid>
                <Grid item/>
            </Grid>
            <Grid item lg={6} xs={12}>
                <SearchBar searchText={searchText} setSearchText={setSearchText} searchCriteria={searchCriteria}
                           setSearchCriteria={setSearchCriteria}/>
            </Grid>
            <Grid item lg={6} xs={12}>
                <SetOrder order={order} setOrder={setOrder}/>
            </Grid>
            <Grid item lg={6} xs={12} style={{textAlign: "center"}}>
                <StatusFilter filteredStatus={filteredStatus} setFilteredStatus={setFilteredStatus}/>
            </Grid>
            <Grid item lg={6} xs={12}>
                <GoToPage page={page} setPage={setPage} totalCount={totalCount} rowsPerPage={rowsPerPage}/>
            </Grid>
            <Grid item lg={12} xs={12}>
                <SetLastConfessionID lastconfessionid={lastconfessionid} setLastconfessionid={setLastconfessionid}/>
            </Grid>

            <Grid item container direction={"row"} alignItems={"flex-end"} justifyContent={"flex-end"}>

                <Grid item xs={12}>
                    <TablePagination
                        component={"div"}
                        count={totalCount}
                        page={(page > numOfPages) ? 0 : page}
                        labelRowsPerPage={"Confessions per page"}
                        onPageChange={(e, pageNumber) => {
                            setPage(pageNumber)
                        }}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={(e) => {
                            setRowsPerPage(parseInt(e.target.value, 10))
                        }}
                        rowsPerPageOptions={[]}
                    />
                </Grid>
            </Grid>
            {Object.keys(confessions).map((keyname, i) => {
                if (filteredStatus.includes(confessions[keyname].status as StatusString)) {
                    return (
                        <Grid key={i} item xs={12} sm={6} md={4} component={"div"}>
                            <ConfessionCard filteredStatus={filteredStatus}
                                            removeConfession={() => removeConfession(keyname)}
                                            setTotalCount={()=>setTotalCount(totalCount-1)}
                                            lastconfessionid={lastconfessionid}
                                            setLastconfessionid={setLastconfessionid}
                                            setConfessions={setConfessions}
                                            keyname={keyname}
                                            index={i}
                                            {...confessions[keyname]}
                                            copyFlag={copyFlags[i]}
                                            setCopyFlags={setCopyFlags}
                            />
                        </Grid>
                    )
                } else {
                    return ""
                }
            })}
            <Grid item xs={12}>
                <TablePagination
                    component={"div"}
                    count={totalCount}
                    page={(page > numOfPages) ? 0 : page}
                    labelRowsPerPage={"Confessions per page"}
                    onPageChange={(e, pageNumber) => {
                        setPage(pageNumber)
                    }}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={(e) => {
                        setRowsPerPage(parseInt(e.target.value, 10))
                    }}
                    rowsPerPageOptions={[]}
                />
            </Grid>
            <ProgressDialog inProgress={loading} setInProgress={setLoading} errorMsg={errorMsg}/>
        </Grid>
    )
}
/*
    Imports
 */

// React
import React, {FormEvent} from "react";

// Essentials
import axios from "axios"; // Axios
import NumberFormat from "react-number-format"; // React Number Format
import copy from 'clipboard-copy'; // Copy & Paste

// Material UI
import {createTheme, MuiThemeProvider, Theme} from "@material-ui/core/styles";
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid, Link,
    TextField,
    Typography
} from "@material-ui/core";

// Icons
import SendIcon from '@material-ui/icons/Send';
import MessageIcon from "@material-ui/icons/Message";
import InfoIcon from '@material-ui/icons/Info'; // Information icon

// Components
import ProgressDialog from "./ProgressDialog"; // Progress Dialog

// Recaptcha
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {useAppDispatch, useAppSelector} from "../hooks";
import {SUBMIT_FORM} from "../actions/formActions";

// RTL Theme
const RTLTheme = (theme: Theme) => createTheme({
    ...theme,
    direction: 'rtl'
});

export default function SendConfession() {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [activeStep, setActiveStep] = React.useState<number>(0);
    const [message, setMessage] = React.useState<string>("");
    const [comment_of_confession_id, setCommentOfConfessionID] = React.useState<string>("");
    const [isComment, toggleComment] = React.useState<boolean>(false);
    const [inProgress, setInProgress] = React.useState<boolean>(false);
    const [data, setData] = React.useState({generatedPassword: "", _id: 0});
    const [errorMsg, setErrorMsg] = React.useState<string>("");

    const dispatch = useAppDispatch();
    const formState = useAppSelector((state) => state.form)

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        try {
            // Prevent default
            e.preventDefault();
            setErrorMsg("")


            // Handle recaptcha
            if(!executeRecaptcha){
                return;
            }

            const token = await executeRecaptcha("SendConfession");

            // Return nothing if message is empty
            if (message.trim() === "") {
                return false;
            }

            // Set in Progress
            setInProgress(true);

            // HTTP Request
            const confession = await axios.post("/api/confession/create", {
                message,
                comment_of_confession_id: parseInt(comment_of_confession_id),
                token
            });

            // Confession data
            const {generatedPassword, _id} = confession.data; // Get data
            setData({generatedPassword, _id}); // Set state data

            setTimeout(()=>{
                setInProgress(false);
                setActiveStep(1);
                dispatch({type: SUBMIT_FORM})
                setMessage("")
                setCommentOfConfessionID("")
                toggleComment(false)
            },1000);
        } catch (error) {
            let msg = "Unknown error";

            // If connection is lost after 9 seconds after sending the request
            if (error.code === 'ECONNABORTED') {
                msg = 'Connection is lost, please try again later!';
            }

            // If there is any error sent from the server, set error msg to the first error
            if (typeof (error.response) !== 'undefined') {
                msg = error.response.data.errors[0].msg;
            }

            setErrorMsg(msg); // Set error message, TODO: Optimize it
        }
    };

    return (
        <React.Fragment>
            <ProgressDialog inProgress={inProgress} setInProgress={setInProgress} errorMsg={errorMsg}/>
            {!formState.isFormSubmitted ?
                (<form onSubmit={(e: FormEvent<HTMLFormElement>) => handleSubmit(e)}>
                    <Grid container spacing={2}>
                        <Grid container item xs={12} justifyContent={"space-between"}>
                            <Grid item><MessageIcon/></Grid>
                            <Grid item><Typography component="h1" variant="h5">Send a confession</Typography></Grid>
                            <Grid item/>
                        </Grid>
                        <Grid item xs={12}>
                            <MuiThemeProvider theme={RTLTheme}>
                                <div dir={"rtl"}>
                                    <TextField
                                        name="message"
                                        value={message}
                                        variant="outlined"
                                        multiline={true}
                                        required
                                        fullWidth
                                        label="Enter your confession"
                                        minRows={3}
                                        maxRows={Infinity}
                                        onChange={(e) => setMessage(e.target.value)}
                                    />
                                </div>
                            </MuiThemeProvider>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={<Checkbox color="primary" onClick={() => {toggleComment(!isComment); setCommentOfConfessionID("")}}/>}
                                label="Is this a comment for an existing confession?"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <NumberFormat
                                variant="outlined"
                                required
                                label={"Enter existing confession ID"}
                                name="comment_of_confession_id"
                                customInput={TextField}
                                disabled={!isComment}
                                onChange={(e) => {
                                    setCommentOfConfessionID(e.target.value);
                                }}
                                value={comment_of_confession_id}
                                fullWidth
                                allowEmptyFormatting
                                allowNegative={false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                type="submit"
                                fullWidth
                                color={"primary"}
                                endIcon={<SendIcon/>}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>) : (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography component="h1" variant="h5" style={{textAlign: "center"}}>
                                Your confession is pending approval!
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{textAlign: "center"}}>
                            <Typography component="span" variant="body1" style={{textAlign: "center"}}>
                                Track the status of your confession using the following code:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{textAlign: "center"}}>
                            <TextField
                                fullWidth
                                label="Click to copy"
                                defaultValue={`${data._id}-${data.generatedPassword}`}
                                inputProps={{style: {textAlign: 'center'}}}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="filled"
                                onClick={() => copy(`${data._id}-${data.generatedPassword}`)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div className="alert alert-success" style={{textAlign: "center"}}>
                                <Typography component="span" variant="body1" style={{textAlign: "center"}}>
                                    Thank you for sharing, keep supporting our <Link
                                    href="https://www.facebook.com/TechnionConfessions" target="_blank" component={"a"} color={"inherit"}>Facebook
                                    page</Link>!
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <InfoIcon style={{verticalAlign: "bottom"}} />
                                <b>&nbsp;Please note:</b>
                                <Typography component="span" variant="body1">
                                    <ul>
                                        <li>Make sure to store the tracking code somewhere safe.</li>
                                        <li>The tracking code <i>cannot</i> be restored once you leave this page.</li>
                                        <li>It should take about a week upon approval or rejection of your confession.
                                        </li>
                                        <li>If your confession is approved - it will be published on our Facebook page
                                            within days.
                                        </li>
                                        <li>If for any reason you regret sending the confession, please contact the
                                            admins on our Facebook page and state your tracking code.
                                        </li>
                                        <li>Your confession may be rejected even if it follows the rules.</li>
                                    </ul>
                                </Typography>
                        </Grid>
                    </Grid>
                )
            }
        </React.Fragment>
    );
}
/**
 * GoToPage
 */

/*
    Imports
 */

// React
import React, {useEffect, useState} from "react";
import {Button, FormControl, FormHelperText, TextField} from "@material-ui/core";
import NumberFormat from "react-number-format";

export default function GoToPage(props: { page: number, setPage: (page: number) => void, totalCount: number, rowsPerPage: number }) {
    // Props
    const {page, setPage, totalCount, rowsPerPage} = props;
    const numOfPages = Math.max(0,Math.ceil((totalCount)/ rowsPerPage)-1);

    // State
    const [goToPage, setGoToPage] = useState(page);

    useEffect(() => {
            setGoToPage(page);
    }, [page]);

    return (
        <form>
            <FormControl fullWidth>
                <NumberFormat
                    customInput={TextField}
                    allowNegative={false}
                    allowLeadingZeros={false}
                    label={"Page:"}
                    value={goToPage}
                    decimalSeparator={""}
                    isAllowed={(values) => {
                        const {formattedValue, floatValue} = values;
                        if (floatValue == null) {
                            return formattedValue === ''
                        } else {
                            return (floatValue <= numOfPages && floatValue >= 0)
                        }
                    }}
                    onValueChange={(values) => {
                            setGoToPage(parseInt(values.value));
                    }}
                />
                <FormHelperText>out of {numOfPages}</FormHelperText>

            <Button type={"submit"} onClick={(e) => {
                e.preventDefault();
                setPage(goToPage);
            }}>GO</Button>
            </FormControl>
        </form>
    );
}
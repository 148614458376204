/**
 * GoToPage
 */

/*
    Imports
 */

// React
import React, {useEffect, useState} from "react";
import {Button, TextField} from "@material-ui/core";
import NumberFormat from "react-number-format";
import axios from "axios";

export default function SetLastConfessionID(props: {lastconfessionid: string, setLastconfessionid: (lastconfessionid: string)=>void}) {
    // State
    const [lastconfessionid, setLastconfessionid] = useState<string>(props.lastconfessionid);

    useEffect(()=>{
        setLastconfessionid(props.lastconfessionid);
    },[props]);

    const handleSubmit = async ()=>{
        props.setLastconfessionid(lastconfessionid);
        await axios.post("/api/user/setlastconfessionid", {lastconfessionid});
    };

    return (
        <React.Fragment>
            {/*<TextField
                label={"Last confession ID"}
                value={lastconfessionid}
                onChange={(e) => {
                    setLastconfessionid(e.target.value);
                }}
            />*/}
            <NumberFormat label={"Last confession id"} customInput={TextField} allowNegative={false} allowLeadingZeros={false} value={parseInt(lastconfessionid)} onBlur={async(e) => {setLastconfessionid(e.target.value)} }/>
            <Button variant={"contained"} color="primary" onClick={handleSubmit}>Update Manually</Button>
        </React.Fragment>
    );
}
/**
 * Logout
 */

/*
    Imports
 */
import React, {useEffect} from "react";
import { Redirect } from "react-router-dom";

/*
    Hook
 */

// TODO: TYPING
export default function Logout(props: {setLoggedIn:(loggedIn: boolean)=>void}){

    useEffect(()=>{
        document.cookie = "payload=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        localStorage.removeItem('filteredStatus');
        props.setLoggedIn(false);
    },[]);

    return (
        <Redirect to={"/"} />
    )
}
/*
    Imports
 */

// React
import React from "react";
import {Grid, Link, List, ListItem, ListItemIcon, ListItemText, Typography} from "@material-ui/core";
import PhoneIcon from '@material-ui/icons/Phone';
import LinkIcon from '@material-ui/icons/Link';
import AddAlertIcon from "@material-ui/icons/AddAlert";

export default function Emergency(){
    return (
        <Grid container spacing={2} component={"div"}>
            <Grid container item xs={12} justifyContent={"space-between"}>
                <Grid item><AddAlertIcon /></Grid>
                <Grid item><Typography component="h1" variant="h5">Emergency hotlines</Typography></Grid>
                <Grid item/>
            </Grid>
            <Grid item xs={12}>
                <List dense={false}>
                    <ListItem>
                        <ListItemIcon>
                            <Link color={"inherit"} href={"tel:077-8874412"}><PhoneIcon /></Link>
                        </ListItemIcon>
                        <ListItemText>
                            <ListItemText
                                primary="Technion Commission for the Prevention of Sexual Harassment"
                                secondary={<span>Phone: 04-8295680; Website: <Link color={"inherit"} href={"https://www.technion.ac.il/%D7%9E%D7%A0%D7%99%D7%A2%D7%AA-%D7%94%D7%98%D7%A8%D7%93%D7%94-%D7%9E%D7%99%D7%A0%D7%99%D7%AA-%D7%91%D7%98%D7%9B%D7%A0%D7%99%D7%95%D7%9F/"} target={"_blank"}>technion.ac.il/מניעת-הטרדה-מינית-בטכניון</Link></span>}
                            />
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Link color={"inherit"} href={"tel:077-8874412"}><PhoneIcon /></Link>
                        </ListItemIcon>
                        <ListItemText>
                            <ListItemText
                                primary="Technion Dean Center for Counseling and Support"
                                secondary={<span>Phone: 077-8874412; Website: <Link color={"inherit"} href={"https://dean.technion.ac.il/center-for-counseling-n-support"} target={"_blank"}>https://dean.technion.ac.il/center-for-counseling-n-support</Link></span>}
                            />
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Link color={"inherit"} href={"tel:04-8292908"}><PhoneIcon /></Link>
                        </ListItemIcon>
                        <ListItemText>
                            <ListItemText
                                primary="Technion Counseling Center"
                                secondary={<span>Phone: 04-8292908, 04-8292909; Website: <Link color={"inherit"} href={"https://psyche.web.technion.ac.il"} target={"_blank"}>https://psyche.web.technion.ac.il</Link></span>}
                            />
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Link color={"inherit"} href={"tel:053-4318934"}><PhoneIcon /></Link>
                        </ListItemIcon>
                        <ListItemText>
                            <ListItemText
                                primary="TechnoQueers - The proud fraternity at the Technion"
                                secondary={<span>Phone: 053-4318934; Website: <Link color={"inherit"} href={"https://www.facebook.com/TechnionLGBT"} target={"_blank"}>https://www.facebook.com/TechnionLGBT</Link></span>}
                            />
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><Link color={"inherit"} href={"tel:1201"}><PhoneIcon /></Link></ListItemIcon>
                        <ListItemText
                            primary="ERAN - Emotional First Aid by Telephone & Internet"
                            secondary={<span>Phone: 1201; Website: <Link color={"inherit"} href={"https://www.eran.org.il"} target={"_blank"}>https://www.eran.org.il</Link></span>}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Link color={"inherit"} href={"https://www.sahar.org.il/"} target={"_blank"}><LinkIcon /></Link>
                        </ListItemIcon>
                        <ListItemText>
                            <ListItemText
                                primary="Sahar - On-line assistance to people experiencing emotional distress"
                                secondary={<span>Website: <Link color={"inherit"} href={"https://www.sahar.org.il/"} target={"_blank"}>https://www.sahar.org.il</Link></span>}
                            />
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Link color={"inherit"} href={"tel:072-2220202"}><PhoneIcon /></Link>
                        </ListItemIcon>
                        <ListItemText>
                            <ListItemText
                                primary="AlKhat - Free assistance in Arabic for the LGBT community"
                                secondary={<span>Phone: 072-2220202; Website: <Link color={"inherit"} href={"https://alkhat.org"} target={"_blank"}>https://alkhat.org</Link></span>}
                            />
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Link color={"secondary"} href={"tel:1202"}><PhoneIcon/></Link>
                        </ListItemIcon>
                        <ListItemIcon>
                            <Link color={"primary"} href={"tel:1203"}><PhoneIcon/></Link>
                        </ListItemIcon>
                        <ListItemText>
                            <ListItemText
                                primary="Association of Rape Crisis Centers in Israel"
                                secondary={<span>Phone: 1202 (Women), 1203 (Men); Website: <Link  color={"inherit"} href={"https://www.1202.org.il"} target={"_blank"}>https://www.1202.org.il</Link></span>}
                            />
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Link color={"inherit"} href={"tel:03-6205591"}><PhoneIcon /></Link>
                        </ListItemIcon>
                        <ListItemText>
                            <ListItemText
                                primary="The Aguda – Israel's LGBT Task Force"
                                secondary={<span>Phone: 03-6205591; Website: <Link color={"inherit"} href={"https://www.lgbt.org.il/"} target={"_blank"}>https://www.lgbt.org.il</Link></span>}
                            />
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Link color={"inherit"} href={"tel:052-4776707"}><PhoneIcon /></Link>
                        </ListItemIcon>
                        <ListItemText>
                            <ListItemText
                                primary="Ma'avarim - Israeli Trans Community"
                                secondary={<span>Phone: 052-4776707; Website: <Link color={"inherit"} href={"https://www.maavarim.org/"} target={"_blank"}>https://www.maavarim.org</Link></span>}
                            />
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Link color={"inherit"} href={"tel:*8944"}><PhoneIcon /></Link>
                        </ListItemIcon>
                        <ListItemText>
                            <ListItemText
                                primary="IDF Disabled Veterans Organization"
                                secondary={<span>Phone: *8977; Website: <Link color={"inherit"} href={"https://www.inz.org.il/blog/%D7%A7%D7%95-%D7%AA%D7%9E%D7%99%D7%9B%D7%94-%D7%95%D7%A1%D7%99%D7%95%D7%A2-%D7%98%D7%9C%D7%A4%D7%95%D7%A0%D7%99/"} target={"_blank"}>https://www.inz.org.il</Link></span>}
                            />
                        </ListItemText>
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    );
}
import {AnyAction} from "redux";
import {THEME_CHANGE} from "../actions/themeActions";

const initialState = {
    isThemeLight: true,
}

export default function themeReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case THEME_CHANGE:
            return {...state, isThemeLight: !state.isThemeLight}
        default:
            return state
    }
}
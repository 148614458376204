/**
 * Progress Dialog
 */

/*
    Imports
 */

// React
import React from "react";
import {
    Button,
    CircularProgress,
    createStyles,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Theme
} from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            textAlign: "center",
            position: "absolute",
            top: "0%",
            left: "0%"
        }
    })
);

// TODO: change setInprogress to setstate type
export default function ProgressDialog(props: { inProgress: boolean, setInProgress: (inProgress: boolean) => void, errorMsg: string }) {
    const {inProgress, setInProgress, errorMsg} = props;

    // Classes
    const classes = useStyles();

    return (
        <Dialog open={inProgress} className={classes.dialog}>
            <DialogTitle>{!errorMsg ? 'Processing your confession...' : 'There was an error :-('}</DialogTitle>
            <DialogContent>
                <Grid container direction="row" justifyContent="center" component={"div"}>
                    <Grid item xs={12} component={"div"}>
                        {!errorMsg ? <CircularProgress/> : ''}
                    </Grid>
                    <Grid item component={"div"}>
                        <DialogContentText>
                            {!errorMsg ? (
                                    <span>We thank you for your patience! &nbsp;<FavoriteIcon color={"secondary"}/></span>
                                )
                                :
                                (
                                    <span>
                                                {errorMsg}<br/>
                                                <Button onClick={() => setInProgress(false)}>Close</Button>
                                        </span>
                                )
                            }
                        </DialogContentText>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
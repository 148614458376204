import React, {useEffect} from "react"
import type {ConfessionCardType} from "../../../server/models/Confession/ConfessionDocument"
import type {StatusString} from "../../../server/models/Confession/ConfessionCollection"
import {ReasonOfRejectionString} from "../../../shared/types/ReasonOfRejection"
import axios from "axios"
import moment from "moment"
import copy from "clipboard-copy"
import {makeStyles, Theme, useTheme} from "@material-ui/core/styles"
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    createStyles,
    FormControl,
    FormHelperText,
    IconButton,
    Link,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@material-ui/core"

import CancelIcon from "@material-ui/icons/Cancel"
import DoneIcon from "@material-ui/icons/Done"
import PublishIcon from "@material-ui/icons/Publish"
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight'
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft"
import ZoomInIcon from "@material-ui/icons/ZoomIn"
import DeleteIcon from "@material-ui/icons/Delete"


import ConfessionFullscreen from "./ConfessionFullscreen"


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        confessionCard: {
            border: "1px solid",
            maxWidth: 600,
            [theme.breakpoints.down("md")]: {
                maxWidth: 300
            }
        },
        confessionCardContent: {
            height: "150px",
            overflowY: "auto",
        },
        confessionText: {
            whiteSpace: "break-spaces",
            textAlign: "justify",
            wordBreak: "break-word"
        },
        confessionActions: {
            justifyContent: 'center'
        },
        rejected: {
            color: theme.palette.error.main
        },
        approved: {
            color: theme.palette.success.main
        }
    }))

type Props = ConfessionCardType
    & {
    filteredStatus: StatusString[],
    removeConfession: () => void,
    lastconfessionid: string,
    setLastconfessionid: (arg0: string) => void,
    setConfessions: (arg0: (prevState: any) => any) => void,
    keyname: string,
    index: number,
    copyFlag: boolean,
    setCopyFlags: React.Dispatch<React.SetStateAction<boolean[]>>,
    setTotalCount: () => void
}

export default function ConfessionCard(props: Props) {
    const {_id, comment_of_confession_id} = props
    const [alignment, setAlignment] = React.useState<"rtl" | "ltr">("rtl")
    const [openFullscreenDialog, setOpenFullscreenDialog] = React.useState<boolean>(false)
    const [openComment, setOpenComment] = React.useState<boolean>(false)
    const classes = useStyles()
    const theme = useTheme()
    const [fbPostId, setFbPostId] = React.useState<string | undefined>(props.fb_post_id)

    useEffect(() => {
        setFbPostId(props.fb_post_id)
    }, [props.fb_post_id])

    const handleDelete = async () => {
        try {
            if (!window.confirm("Are you sure about deleting this confession?")) {
                return
            }

            await axios.post('/api/confession/delete', {_id})
            props.removeConfession()
        } catch (e) {
            window.alert("Error while deleting confession")
        }
    }

    const regex = `^(?:https?:\\/\\/(?:(?:www|business)\\.)?facebook\\.com\\/${process.env.FACEBOOK_PAGE_ID}\\/posts\\/)?(\\d{14}\\d+|pfbid\\w+)(?:\\?.+)?`

    const handleUpdate = async (
        newStatus = props.status as StatusString,
        newReasonOfRejection = props.reason_of_rejection,
        newFBPostID = props.fb_post_id
    ) => {
        try {
            let {confession_id} = props
            let [status, reason_of_rejection, fb_post_id] = [newStatus, newReasonOfRejection, newFBPostID]

            // TODO: Figure it out
            if (fb_post_id === props.fb_post_id) {
                props.setCopyFlags(prevState => {
                    return Array(prevState.length).fill(false)
                })
            }

            if (status !== props.status) {
                switch (status) {
                    case "rejected":
                        fb_post_id = ""
                        break

                    case "approved":
                        break

                    case "published":
                        if (props.status !== "approved")
                            return
                        confession_id = parseInt(props.lastconfessionid) + 1
                        await axios.post("/api/user/setlastconfessionid", {lastconfessionid: confession_id})
                        props.setLastconfessionid(String(confession_id))
                }
            }

            await axios.post('/api/confession/update', {
                _id,
                status,
                reason_of_rejection,
                confession_id,
                fb_post_id
            })

            props.setConfessions((prevState) => {
                return {
                    ...prevState,
                    [props.keyname]: {
                        ...prevState[props.keyname],
                        status,
                        reason_of_rejection,
                        confession_id,
                        fb_post_id
                    }
                }
            })

            if (!props.filteredStatus.includes(status as StatusString))
                props.setTotalCount()
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Card className={classes.confessionCard}>
            <CardHeader
                title={<span>
                    {props.status.toUpperCase() + " "}
                    {props.status === "published" && props.confession_id ? "#" + props.confession_id + " " : " "}
                    {props.status === "published" && props.fb_post_id ? <Link target={"_blank"} color={"inherit"}
                                                                              href={"https://www.facebook.com/" + process.env.FACEBOOK_PAGE_ID + "/posts/" + props.fb_post_id}>(FB)</Link> : ""}
                    </span>} subheader={
                <Typography color="textSecondary" component={"div"}>
                    Object ID: {_id}<br/>
                    Created at: {moment(props.created_at).format("DD/MM/YYYY, HH:mm:ss")}<br/>
                    {props.created_at !== props.updated_at ? `Updated at: ${moment(props.updated_at).format("DD/MM/YYYY, HH:mm:ss")}` : ""}<br/>
                    {
                        props.comment_of_confession_id ?
                            <React.Fragment>
                                <span>Comment of confession: </span>
                                {props.comment_of[0] ?
                                    <React.Fragment>
                                        <Link color={"inherit"} href={"#"}
                                              onClick={(e: { preventDefault: () => void }) => {
                                                  e.preventDefault()
                                                  setOpenComment(true)
                                              }}>#{props.comment_of_confession_id} </Link>
                                        {props.comment_of[0].fb_post_id ? <Link target={"_blank"} color={"inherit"}
                                                                                href={"https://www.facebook.com/" + process.env.FACEBOOK_PAGE_ID + "/posts/" + props.comment_of[0].fb_post_id}>(FB)</Link> : ""}
                                        <ConfessionFullscreen openFullscreenDialog={openComment}
                                                              setOpenFullscreenDialog={setOpenComment}
                                                              message={props.comment_of[0] ? props.comment_of[0].message : ""}
                                        />
                                    </React.Fragment>
                                    :
                                    <span> #{comment_of_confession_id} (Not in DB)</span>
                                }
                            </React.Fragment>
                            :
                            ""
                    }<br/>
                    <Typography component={"div"} style={{textAlign: "right"}}>
                        <IconButton onClick={() => setOpenFullscreenDialog(true)}><ZoomInIcon/></IconButton>
                        <ConfessionFullscreen openFullscreenDialog={openFullscreenDialog}
                                              setOpenFullscreenDialog={setOpenFullscreenDialog}
                                              message={props.message}
                        />
                        <IconButton onClick={() => setAlignment("ltr")}><FormatAlignLeftIcon/></IconButton>
                        <IconButton
                            onClick={() => setAlignment("rtl")}><FormatAlignRightIcon/></IconButton>
                    </Typography>
                </Typography>
            }/>
            <CardContent className={classes.confessionCardContent}
                         style={{color: (props.copyFlag) ? theme.palette.warning.main : ""}}>
                <Typography component={"div"} variant={"body2"} className={classes.confessionText}
                            style={{direction: alignment} as React.CSSProperties}
                            onClick={async () => {

                                if (props.status === "approved")
                                    props.setCopyFlags(prevState => {
                                        let newState = Array(prevState.length).fill(false)
                                        newState[props.index] = !prevState[props.index]
                                        return newState
                                    })

                                await copy(
                                    ((props.status === "approved") ? `#${parseInt(props.lastconfessionid) + 1} ` : '') +
                                    ((props.status === "published") ? `#${props.confession_id} ` : "") +
                                    ((comment_of_confession_id) ? `תגובה לוידוי ${comment_of_confession_id}: \n` : "") +
                                    props.message +
                                    (comment_of_confession_id && props.comment_of[0].fb_post_id ? "\n\nhttps://www.facebook.com/" + process.env.FACEBOOK_PAGE_ID + "/posts/" + props.comment_of[0].fb_post_id + "\n" : "")
                                )
                            }}
                >
                    {(props.status === "approved") ? <Typography component={"div"}
                                                                 style={{
                                                                     color: (props.copyFlag) ? theme.palette.warning.main : "",
                                                                     display: (props.copyFlag) ? "inline" : "none",
                                                                     fontWeight: "bold"
                                                                 }}>#{parseInt(props.lastconfessionid) + 1} </Typography> : ""}
                    {(props.status === "published") ? `#${props.confession_id} ` : ""}
                    {(comment_of_confession_id) ? `תגובה לוידוי ${comment_of_confession_id}: \n` : ""}
                    {props.message}
                </Typography>
            </CardContent>
            <CardActions className={classes.confessionActions}>
                <IconButton className={(props.status === "rejected") ? classes.rejected : ""}
                            onClick={async () => await handleUpdate("rejected")}><CancelIcon/></IconButton>
                <IconButton
                    className={(props.status === "approved" || props.status === "published") ? classes.approved : ""}
                    onClick={async () => {
                        await handleUpdate("approved")
                    }}><DoneIcon/></IconButton>
                <IconButton className={(props.status === "published") ? classes.approved : ""} onClick={async () => {
                    await handleUpdate("published")
                }}><PublishIcon/></IconButton>
                {<IconButton onClick={handleDelete}><DeleteIcon/></IconButton>}
            </CardActions>
            <CardActions className={classes.confessionActions}
                         style={{display: (props.status === "rejected" || props.status === "pending") ? "inline" : "none"}}>
                <FormControl fullWidth>
                    <Select
                        value={props.reason_of_rejection && props.reason_of_rejection > 0 ? props.reason_of_rejection : 0}
                        displayEmpty onChange={async (e) => {
                        await handleUpdate("rejected", e.target.value as number)
                    }}>
                        {Object.keys(ReasonOfRejectionString).map((k) => {
                            return <MenuItem key={k as unknown as number}
                                             value={k as unknown as number}>{ReasonOfRejectionString[k as keyof typeof ReasonOfRejectionString]}</MenuItem>
                        })}
                    </Select>
                    <FormHelperText style={{textAlign: "center"}}>Reason of rejection</FormHelperText>
                </FormControl>
            </CardActions>
            <CardActions className={classes.confessionActions}
                         style={{display: (props.status === "published" || props.status === "approved") ? "block" : "none"}}>
                <FormControl fullWidth>
                    <TextField
                        value={fbPostId}
                        onChange={async (e) => {
                            setFbPostId(e.target.value)
                        }}
                        onBlur={async (e) => {
                            let newFbPostId = props.fb_post_id || ""

                            if (fbPostId!.match(/^\s*$/)) {
                                newFbPostId = ""
                            } else if (!fbPostId!.match(regex)) {
                                setFbPostId(newFbPostId)
                                return
                            } else {
                                newFbPostId = fbPostId!.match(regex)![1]
                            }

                            setFbPostId(newFbPostId)
                            await handleUpdate(props.status as StatusString, 0, newFbPostId)
                        }}/>
                    <FormHelperText style={{textAlign: "center"}}>Facebook post ID</FormHelperText>
                </FormControl>
            </CardActions>
        </Card>
    )
}
/**
 * GoToPage
 */

/*
    Imports
 */

// React
import React, {useEffect, useState} from "react";
import {Checkbox, FormControl, FormControlLabel, FormGroup} from "@material-ui/core";

// Types
import {StatusBooleanObject} from "../../Types/Status";
import {StatusString} from "../../../server/models/Confession/ConfessionCollection";

export default function StatusFilter(props: { filteredStatus: StatusString[], setFilteredStatus: (filteredStatus: StatusString[]) => void }) {
    // Props
    const {filteredStatus, setFilteredStatus} = props;

    // State

    // Define initial state
    const initialState: StatusBooleanObject = {
        pending: false,
        approved: false,
        rejected: false,
        published: false,
    };

    // Set to true default given in props
    filteredStatus.map((k) => {
        initialState[k] = true;
    });
    const [checkedStatus, setCheckedStatus] = useState(initialState);

    // Handlers
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedStatus({...checkedStatus, [event.target.name]: event.target.checked});
    };

    const handleAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedStatus({
            pending: event.target.checked,
            approved: event.target.checked,
            rejected: event.target.checked,
            published: event.target.checked
        });
    };

    useEffect(() => {
        // Convert to status types array
        let filteredStatus: StatusString[] = [];
        Object.keys(checkedStatus).map((key) => {
            const k = key as keyof typeof checkedStatus;
            if (checkedStatus[k])
                filteredStatus.push(k);
        });

        setFilteredStatus(filteredStatus);
    }, [checkedStatus]);

    // Render
    return (
        <FormControl component="fieldset">
            <FormGroup row={true}>
                <FormControlLabel
                    control={<Checkbox name={"all"} onChange={handleAll}
                                       checked={Object.values(checkedStatus).every(e => e)}/>}
                    label={"ALL"}
                />
                {
                    Object.keys(checkedStatus).map((key, number) => {
                        return (<FormControlLabel key={number}
                                                  control={<Checkbox name={key} onChange={handleChange}
                                                                     checked={checkedStatus[key as keyof typeof checkedStatus]}/>}
                                                  label={key.toUpperCase()}
                        />)
                    })
                }
            </FormGroup>
        </FormControl>
    );
}
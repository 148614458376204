import React, {FormEvent} from "react"
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Link,
    TextField,
    Typography,
    useTheme
} from "@material-ui/core"
import {useGoogleReCaptcha} from "react-google-recaptcha-v3"
import SearchIcon from "@material-ui/icons/Search"
import SendIcon from "@material-ui/icons/Send"
import axios from "axios"
import ProgressDialog from "./ProgressDialog"
import {ReasonOfRejectionString} from "../../shared/types/ReasonOfRejection"
import moment from "moment"
import {SUBMIT_FORM} from "../actions/formActions"
import {useAppDispatch, useAppSelector} from "../hooks"

export default function TrackConfession() {
    const theme = useTheme()
    const {executeRecaptcha} = useGoogleReCaptcha()
    const [inProgress, setInProgress] = React.useState<boolean>(false)
    const [data, setData] = React.useState({
        status: "",
        updated_at: new Date(),
        created_at: new Date(),
        fb_post_id: "",
        confession_id: 0,
        message: "",
        reason_of_rejection: 0,
        askedRejection: false
    })
    const [trackingCode, setTrackingCode] = React.useState<string>("")
    const [askedRejectionCheckbox, setAskedRejectionCheckbox] = React.useState<boolean>(false)
    const [errorMsg, setErrorMsg] = React.useState<string>("")
    const dispatch = useAppDispatch()
    const formState = useAppSelector((state) => state.form)


    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault()
            setErrorMsg("")

            if (!executeRecaptcha) {
                return
            }

            const token = await executeRecaptcha("TrackConfession")
            const trackingCodePattern = RegExp(`^([a-zA-Z0-9]{24})-([a-zA-Z0-9]{${process.env.PASSWORD_LENGTH}})$`)
            const splitCode = trackingCodePattern.exec(trackingCode)
            let generatedPassword = ""
            let _id = ""

            if (splitCode) {
                _id = splitCode[1]
                generatedPassword = splitCode[2]
            } else {
                return
            }
            setInProgress(true)
            const r = await axios.post("/api/confession/track", {
                generatedPassword,
                _id,
                token,
                "askedRejection": askedRejectionCheckbox
            })

            const {status, confession_id, fb_post_id, updated_at, created_at, reason_of_rejection, message, askedRejection} = r.data.confession
            setData({status, confession_id, fb_post_id, updated_at, created_at, reason_of_rejection, message, askedRejection})

            setTimeout(() => {
                setInProgress(false)
                dispatch({type: SUBMIT_FORM})
                // setTrackingCode("")
            }, 1000)
        } catch (error) {
            let msg = "Unknown error"

            if (error.code === 'ECONNABORTED') {
                msg = 'Connection is lost, please try again later!'
            }

            if (typeof (error.response) !== 'undefined') {
                msg = error.response.data.errors[0].msg
            }

            setErrorMsg(msg)
        }
    }

    const statusDescription = () => {
        switch (data.status) {
            case 'rejected':
                return <span><span style={{color: theme.palette.error.main}}>Your confession was rejected.</span></span>
            case 'approved':
                return <span><span style={{color: theme.palette.info.main}}>Your confession was approved.</span><br/>It will be published on our Facebook page within days.</span>
            case 'published':
                return <span><span style={{color: theme.palette.success.main}}>Your confession has been published on our Facebook page.</span><br/>Do not worry if you can't see it yet - it is scheduled.</span>
            case 'pending':
                return <span><span
                    style={{color: theme.palette.warning.main}}>Your confession is pending approval.</span></span>
            default:
                return <span>Your confession status is unknown. If you see this message, please contact admins.</span>
        }
    }

    return (
        <React.Fragment>
            <ProgressDialog inProgress={inProgress} setInProgress={setInProgress} errorMsg={errorMsg}/>
            {!formState.isFormSubmitted ?
                (<form onSubmit={(e: FormEvent<HTMLFormElement>) => handleSubmit(e)}>
                        <Grid container spacing={2}>
                            <Grid container item xs={12} justifyContent={"space-between"}>
                                <Grid item><SearchIcon/></Grid>
                                <Grid item><Typography component="h1" variant="h5">Track your
                                    confession</Typography></Grid>
                                <Grid item/>
                            </Grid>
                            <Grid item xs={12} component={"div"}>
                                <FormControl fullWidth>
                                    {/*<InputLabel variant={"outlined"}>* Enter your tracking code</InputLabel>*/}
                                    <TextField
                                        value={trackingCode}
                                        variant={"outlined"}
                                        label="Enter tracking code"
                                        helperText={""}
                                        onChange={(e) => {
                                            setTrackingCode(e.target.value)
                                        }}
                                        margin="normal"
                                        required
                                    />
                                </FormControl>
                                <FormControlLabel
                                    control={<Checkbox
                                        value = {askedRejectionCheckbox}
                                        onChange={(e) => setAskedRejectionCheckbox(e.target.checked)}
                                    />}
                                    label={"Please reject my confession"}
                                />
                            </Grid>

                            <Grid item xs={12} component={"div"}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    fullWidth
                                    endIcon={<SendIcon/>}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                ) : (
                    <div>
                        <Typography component="h1" variant="h5" style={{textAlign: "center"}}>
                            Track your confession - Results
                        </Typography>
                        <Box fontStyle={"italic"} textAlign={"left"}>
                            Tracking code: {trackingCode}<br/>
                            Updated at: {moment(data.updated_at).format("DD/MM/YYYY, HH:mm:ss")}<br/>
                            Created at: {moment(data.created_at).format("DD/MM/YYYY, HH:mm:ss")}
                        </Box>
                        <hr/>
                        <Typography component="h5" variant="body1" style={{textAlign: "center"}}>
                            {data.message}
                        </Typography>
                        <br/>
                        <Box>
                            <Typography component="h2" variant={"h6"} style={{textAlign: "center"}}>
                                {(data.askedRejection && data.status !== 'rejected') ? <span style={{color: theme.palette.error.main}}>Your confession could not be rejected because it was not in pending status when you asked for it.<br/></span> : ''}
                                {statusDescription()}<br/>
                                {(data.status === 'rejected') ? `Reason of rejection: ${ReasonOfRejectionString[data.reason_of_rejection.toString() as keyof typeof ReasonOfRejectionString]}` : ''}
                                {(data.status === 'published' && data.fb_post_id) ?
                                    <Link href={"https://www.facebook.com/TechnionConfessions/posts/" + data.fb_post_id}
                                          target="_blank" color={"inherit"}>Click here to read</Link> : ''}
                            </Typography>
                        </Box>
                    </div>
                )}
        </React.Fragment>
    )
}
import React from "react";
import {
    AppBar,
    Avatar,
    createStyles,
    Divider,
    Drawer,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    Theme,
    Toolbar,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import MessageIcon from "@material-ui/icons/Message";
import SearchIcon from "@material-ui/icons/Search";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebook} from "@fortawesome/free-brands-svg-icons";
import {Link as RouterLink} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../hooks";
import Brightness4Icon from '@material-ui/icons/Brightness4';
import {THEME_CHANGE} from "../actions/themeActions";
import {RESET_FORM} from "../actions/formActions";

export const drawerWidth = 300;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex"
        },
        appBar: {
            position: "fixed",
            zIndex: theme.zIndex.drawer + 1
        },
        darkThemeAvatar: {
            marginRight: theme.spacing(2),
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.text.primary
        },
        lightThemeAvatar: {
            marginRight: theme.spacing(2),
            color: theme.palette.primary.main,
            backgroundColor: '#FFFFFF',
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        title: {
            flexGrow: 1,
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: "center",
        },
        emergency: {
            '&:hover': {
                backgroundColor: 'red',
                color: 'white'
            }
        }
    })
);


export default function Header(props: { loggedIn: boolean }) {
    const {loggedIn} = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const themeState = useAppSelector((state) => state.theme)
    const handleDrawerToggle = (flag: boolean) => {
        if (flag)
            setMobileOpen(!mobileOpen);
    };

    const classes = useStyles();
    const dispatch = useAppDispatch();
    const formState = useAppSelector((state) => state.form)

    const CustomizedMenuItem = (props: { text: string, icon: React.ReactNode, flag: boolean, route: string, className?: string, hideWhenLoggedIn?: boolean, hideWhenNotLoggedIn?: boolean }) => {
        let display = "inherit";

        if (loggedIn && props.hideWhenLoggedIn) {
            display = "none";
        }

        if (!loggedIn && props.hideWhenNotLoggedIn) {
            display = "none";
        }

        return (
            <ListItem button={true} onClick={() => {
                handleDrawerToggle(props.flag)
                if(formState)
                    dispatch({type: RESET_FORM})}
            } className={props.className}
                      component={RouterLink} to={props.route}
                      style={{display}}
            >
                <ListItemIcon style={{color: "inherit"}}>{props.icon}</ListItemIcon>
                <Typography variant="inherit">{props.text}</Typography>
            </ListItem>
        );
    };

    const menuItems = [
        {
            text: "Send a confession",
            icon: <MessageIcon/>,
            route: "/",
            hideWhenLoggedIn: false,
            hideWhenNotLoggedIn: false
        },
        {
            text: "Track your confession",
            icon: <SearchIcon/>,
            route: "/track",
            hideWhenLoggedIn: false,
            hideWhenNotLoggedIn: false
        },
        {
            text: "Emergency hotllines",
            icon: <AddAlertIcon/>,
            route: "/emergency",
            className: classes.emergency,
            hideWhenLoggedIn: false,
            hideWhenNotLoggedIn: false
        },
        {text: "Login", icon: <VpnKeyIcon/>, route: "/login", hideWhenLoggedIn: true, hideWhenNotLoggedIn: false},
        {
            text: "Dashboard",
            icon: <DashboardIcon/>,
            route: "/dashboard",
            hideWhenLoggedIn: false,
            hideWhenNotLoggedIn: true
        },
        {text: "Logout", icon: <ExitToAppIcon/>, route: "/logout", hideWhenLoggedIn: false, hideWhenNotLoggedIn: true}
    ];

    // Navigation Drawer
    const drawer = (flag: boolean) => {
        return (
            <div>
                <Typography variant="h6" className={classes.drawerHeader}/>
                <Divider/>
                <List>
                    {menuItems.map((item, i) => {
                        return <CustomizedMenuItem
                            text={item.text}
                            icon={item.icon}
                            flag={flag}
                            className={item.className}
                            route={item.route}
                            hideWhenLoggedIn={item.hideWhenLoggedIn}
                            hideWhenNotLoggedIn={item.hideWhenNotLoggedIn}
                            key={i}/>
                    })}
                </List>
            </div>
        )
    };

    /*
        Render
     */

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton className={classes.menuButton} edge="start" color="inherit" aria-label="menu"
                                onClick={() => handleDrawerToggle(true)}>
                        <MenuIcon/>
                    </IconButton>
                    <Avatar className={themeState.isThemeLight ? classes.lightThemeAvatar : classes.darkThemeAvatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography variant="h6" className={classes.title}>
                        {process.env.SITE_NAME}
                    </Typography>
                    <IconButton
                        color="inherit"
                        edge="end"
                        onClick={()=>dispatch({type: THEME_CHANGE})}
                    >
                        <Brightness4Icon fontSize={"large"}/>
                    </IconButton>
                    <IconButton
                        color="inherit"
                        edge="end"
                        href={"https://www.facebook.com/" + process.env.FACEBOOK_PAGE_ID}
                        target="_blank"
                    >
                        <FontAwesomeIcon icon={faFacebook} size={"lg"}/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <nav>
                <Hidden smUp implementation="css">
                    <Drawer
                        className={classes.drawer}
                        container={container}
                        variant="temporary"
                        anchor={'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer(true)}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        className={classes.drawer}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer(false)}
                    </Drawer>
                </Hidden>
            </nav>
        </div>
    )
}
/**
 ** Client - index.ts
 **/

/*
    Imports
 */
import {render} from "react-dom";
import React from "react";
import App from "./App";
import {Provider} from "react-redux";
import {persistor, store} from "./store";
import {PersistGate} from "redux-persist/integration/react";

/*
    Render APP
*/
render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
                    <App/>
        </PersistGate>
    </Provider>
    , document.getElementById('root'));
/**
 * App
 */

/*
    Imports
 */

import React, {useEffect} from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {createTheme, CssBaseline, Paper, ThemeProvider} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import SendConfession from "./Components/SendConfession";
import TrackConfession from "./Components/TrackConfession";
import Emergency from "./Components/Emergency";
import Login from "./Components/admin/Login";
import Dashboard from "./Components/admin/Dashboard";
import Logout from "./Components/admin/Logout";
import axios from "axios";
import {useAppSelector} from "./hooks";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {blue} from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    root: {display: "flex"},
    toolbarSpacer: theme.mixins.toolbar,
    layout: {
        flexGrow: 1,
    },
    content: {
        marginLeft: "5%",
        marginRight: "5%",
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
        padding: theme.spacing(2),
    }
}));

const darkTheme = createTheme({
    palette: {
        type: 'dark',
    },
});

const lightTheme = createTheme({
    palette: {
        type: 'light',
        background: {
            default: "#F1F1F1"
        }
    },
});

const App = () => {
    const classes = useStyles();
    const themeState = useAppSelector((state) => state.theme)
    const [loggedIn, setLoggedIn] = React.useState<boolean>(false);

    useEffect(() => {
        (async () => {
            try {
                await axios.post('/api/user/checkaccess'); // Also gets last confession ID
                setLoggedIn(true);
            } catch (e) {
                setLoggedIn(false);
            }
        })();
    }, []);

    return (
        <ThemeProvider theme={themeState.isThemeLight ? lightTheme : darkTheme}>
            <GoogleReCaptchaProvider
                reCaptchaKey={process.env.RECAPTCHA_PUBLIC_KEY}
                language={"en"}
            >
                <div className={classes.root}>
                    <CssBaseline/>
                    <Router>
                        <Header loggedIn={loggedIn}/>
                        <div className={classes.layout}>
                            <div className={classes.toolbarSpacer}/>
                            <Paper className={classes.content}>
                                <Switch>
                                    <Route exact path="/" component={SendConfession}/>
                                    <Route path="/index" component={SendConfession}/>
                                    <Route path="/track" component={TrackConfession}/>
                                    <Route path="/emergency" component={Emergency}/>
                                    <Route path="/login" render={(props) => <Login loggedIn={loggedIn}
                                                                                   setLoggedIn={setLoggedIn} {...props}
                                                                                   key={Math.random()}/>}/>
                                    <Route path="/logout"
                                           render={(props) => <Logout setLoggedIn={setLoggedIn} {...props}
                                                                      key={Math.random()}/>}/>
                                    <Route path="/dashboard"
                                           render={(props) => loggedIn ? <Dashboard {...props} key={Math.random()}/> :
                                               <Redirect to="/login"/>}/>
                                </Switch>
                            </Paper>
                            <Footer/>
                        </div>
                    </Router>
                </div>
            </GoogleReCaptchaProvider>
        </ThemeProvider>
    );
}

export default App;
/**
 * Login
 */

/*
    Imports
 */

// React
import React, {FormEvent} from "react";
import {Button, Grid, TextField, Typography} from "@material-ui/core";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import SendIcon from "@material-ui/icons/Send";
import axios from "axios";
import {Redirect} from 'react-router-dom';
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

export default function Login(props: {loggedIn: boolean, setLoggedIn:(loggedIn: boolean)=>void}) {
    // Recaptcha
    const {executeRecaptcha} = useGoogleReCaptcha();

    // State
    const [username, setUsername] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");
    const [disabledButton, setDisabledButton] = React.useState<boolean>(false);
    const [errorMsg, setErrorMsg] = React.useState<string>("");
    const [redirect, setRedirect] = React.useState<boolean>(false);

    // Handlers
    const handleSubmit = async(e: FormEvent<HTMLFormElement>) =>{
        try {
            // Prevent default
            e.preventDefault();

            // Handle recaptcha
            if (!executeRecaptcha) {
                return;
            }

            const token = await executeRecaptcha("Login");

            // Disable submit button for prevention of flooding
            setDisabledButton(true);
            await axios.post('/api/user/login', {username, password,token}, {withCredentials: true});
            props.setLoggedIn(true);
        }
        catch(obj){ // TODO: handle it better
            let msg = 'Unknown Error';

            // If connection is lost after 9 seconds after sending the request
            if (obj.code === 'ECONNABORTED') {
                msg = 'Connection is lost';
            }

            // If there is any error sent from the server, set error msg to the first error
            if(typeof(obj.response) !== 'undefined') {
                msg = obj.response.data.errors[0].msg;
            }

            setDisabledButton(false);
            console.log(msg)
        }
    };

    // Render
    return (
        <form onSubmit={handleSubmit}>
            {(props.loggedIn)?<Redirect to={"/dashboard"}/>:""}
            <Grid container spacing={2} component={"div"}>
                <Grid container item xs={12} justifyContent={"space-between"}>
                    <Grid item><VpnKeyIcon/></Grid>
                    <Grid item><Typography component="h1" variant="h5">Login</Typography></Grid>
                    <Grid item/>
                </Grid>
                <Grid item xs={12} style={{textAlign: "center"}}>
                    <TextField
                        variant="outlined"
                        required
                        label="Username"
                        name="username"
                        type={"string"}
                        fullWidth
                        onChange={(e)=>{setUsername(e.target.value)}}
                        value={username}
                        margin={"normal"}
                        autoComplete="off"
                    />
                </Grid>
                <Grid item xs={12} style={{textAlign: "center"}}>
                    <TextField
                        variant="outlined"
                        required
                        label="Password"
                        name="password"
                        type={"password"}
                        fullWidth
                        onChange={(e)=>{setPassword(e.target.value)}}
                        value={password}
                        margin={"normal"}
                        autoComplete="off"
                    />
                </Grid>
                <Grid item xs={12} component={"div"}>
                    <Button
                        disabled={disabledButton}
                        variant="contained"
                        color="primary"
                        type="submit"
                        fullWidth
                        endIcon={<SendIcon />}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}
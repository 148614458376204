import {AnyAction} from "redux";
import {RESET_FORM, SUBMIT_FORM} from "../actions/formActions";

const initialState = {
    isFormSubmitted: false,
}

export default function formReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case SUBMIT_FORM:
            return {...state, isFormSubmitted: true}
        case RESET_FORM:
            return {...state, isFormSubmitted: false}
        default:
            return state
    }
}
/**
 * Set Order
 */

import React, {useEffect, useState} from "react";
import {FormControl, MenuItem, Select, FormHelperText} from "@material-ui/core";

export default function SetOrder(props: { order: number, setOrder: (order: number) => void }) {
    const [order, setOrder] = useState(props.order);

    useEffect(() => {
        props.setOrder(order);
    }, [order]);

    return (
        <FormControl fullWidth>
            <Select variant={"standard"} value={order} onChange={(e) => setOrder(e.target.value as number)}>
                <MenuItem value={-1}>Latest to earliest</MenuItem>
                <MenuItem value={1}>Earliest to latest</MenuItem>
            </Select>
            <FormHelperText>Order of results</FormHelperText>
        </FormControl>
    );
}
/**
 * GoToPage
 */

/*
    Imports
 */

// React
import React, {useState} from "react";
import {createStyles, Dialog, DialogContent, IconButton, Toolbar, Typography, useMediaQuery} from "@material-ui/core";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import CloseIcon from "@material-ui/icons/Close";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import copy from 'clipboard-copy'; // Copy & Paste

const useStyles = makeStyles(() =>
    createStyles({
        confessionText: {
            whiteSpace: "pre-line",
            textAlign: "justify",
            wordBreak: "break-word"
        }
    }));

export default function ConfessionFullscreen(props: {message: string, openFullscreenDialog: boolean, setOpenFullscreenDialog: (openFullscreenDialog:boolean)=>void}){

    // Theme
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // Props
    const {openFullscreenDialog,setOpenFullscreenDialog} = props;

    // State
    const [alignment, setAlignment] = useState( "rtl");

    // Classes
    const classes = useStyles();

    return (
        <Dialog fullScreen={fullScreen} open={openFullscreenDialog} onClose={() => {setOpenFullscreenDialog(false)}}>
            <Toolbar>
                <IconButton
                    onClick={() => setAlignment("ltr")}><FormatAlignLeftIcon/></IconButton>
                <IconButton
                    onClick={() => setAlignment("rtl")}><FormatAlignRightIcon/></IconButton>
                <IconButton color={"inherit"} onClick={() => {
                    setOpenFullscreenDialog(false)
                }}><CloseIcon/></IconButton>
            </Toolbar>
            <DialogContent>
                <Typography className={classes.confessionText} variant={"h6"} style={{direction: alignment} as React.CSSProperties} onClick={async ()=>{await copy(props.message)}}>
                    {props.message}
                </Typography>
            </DialogContent>
        </Dialog>
    );
}
// React
import React from "react";
import {createStyles, Theme, Typography,Link} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Link as RouterLink} from "react-router-dom";

const date = new Date(); // Get date
const year = date.getFullYear(); // Get year

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footerText: {
            textAlign: "center"
        },
        emergencyText: {
            color: theme.palette.text.secondary,
            fontWeight: "bold",
            textDecoration: "none"
        }
    }),
)

export default function Footer() {

    // Classes
    const classes = useStyles();

    return (
        <footer>
            <Typography className={classes.footerText} variant="body2" color="textSecondary">
                <span className={classes.emergencyText}> If you're in crisis and need to speak to someone immediately, please contact one of the <Link component={RouterLink} to="/emergency" className={classes.emergencyText}>emergency hotlines</Link></span>
                <br/>
                <br/>
                This site is not affiliated, associated, authorized, endorsed by, or in any way officially
                connected with the Technion - Israeli Institute of Technology.<br/>
                The name "Technion" as well as related names, marks, emblems and images are registered trademarks of the
                Technion - Israeli Institute of Technology.<br/>
                Confessions are moderated by the anonymous admins.<br/>
                This site was developed by Shahar Epstein.<br/>
                All Rights Reserved &copy; {year}
            </Typography>
        </footer>
    )
}
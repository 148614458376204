/**
 * Serach Bar
 */

import React, {useState} from "react";
import {FormControl, MenuItem, Select, TextField, Button, Grid} from "@material-ui/core";

export default function SearchBar(props:
    {
        searchCriteria: string,
        setSearchCriteria: (searchCriteria: string) => void,
        searchText: string,
        setSearchText: (searchText: string) => void
    }) {
    const [searchText, setSearchText] = useState(props.searchText); // TODO: Type it
    const [searchCriteria, setSearchCriteria] = useState(props.searchCriteria); // TODO: Type it

    return (
        <form>
        <FormControl fullWidth>
            <Grid container>
            <Grid item xs={12}><TextField value={searchText} onChange={(e)=>{setSearchText(e.target.value)}} label={"Type to search"} fullWidth/>
            <Select  fullWidth value={searchCriteria} displayEmpty onChange={(e)=>{setSearchCriteria(e.target.value as string)}}>
                <MenuItem value={"message"}>Message</MenuItem>
                <MenuItem value={"_id"}>Object ID</MenuItem>
                <MenuItem value={"confession_id"}>Confession ID</MenuItem>
                <MenuItem value={"comment_of_confession_id"}>Comment of Confession ID</MenuItem>
                <MenuItem value={"fb_post_id"}>FB Post ID</MenuItem>
            </Select>
            </Grid>
            <Grid item xs={6}>
                <Button fullWidth type={"reset"} onClick={(e) => {
                    setSearchText("");
                    props.setSearchText("");
                }}>RESET</Button>
            </Grid>
                <Grid item  xs={6}>
                    <Button fullWidth type={"submit"} onClick={(e) => {
                        e.preventDefault();
                        props.setSearchText(searchText);
                        props.setSearchCriteria(searchCriteria);
                    }}>SEARCH</Button>
                </Grid>
            </Grid>
        </FormControl>
        </form>
    );
}
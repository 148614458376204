export const ReasonOfRejectionString = {
    "0": "None specified",
    "1": "SPAM or unreadable text",
    "2": "Suicide or self-harm content",
    "3": "Requesting or providing medical or legal advice",
    "4": "Harmful content",
    "5": "Inappropriate sexual descriptions",
    "6": "Violates intellectual property",
    "7": "Discloses identity of original poster or non-public figures",
    "8": "Marketing content or advertisements",
    "9": "Due to user's request",
    "10": "Irrelevant"
}